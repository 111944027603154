import $ from 'jquery'

export default (() => {
    const sendAjaxRequest = param => {
        let defaultHeader = {};
        const userToken = localStorage.getItem('userToken');

        if (userToken) {
            defaultHeader = {"Authorization": 'Bearer ' + userToken};
        }

        try {
            const req = {
                type: param.type ? param.type : 'GET',
                dataType: param.dataType ? param.dataType : 'json',
                contentType: param.contentType ? param.contentType : 'application/json; charset=utf-8',
                url: param.url,
                headers: param.headers ? param.headers : defaultHeader,
                crossDomain: true,
                xhrFields: {
                    withCredentials: false
                },
                success: (data, textStatus, request) => {
                    if (typeof param.onSuccess === 'function') {
                        param.onSuccess(data, textStatus, request);
                    }
                },

                error: (error) => {
                    if (error.status !== 403 && typeof param.onError === 'function') { // 403 error response is handled in ajaxSetup complete
                        param.onError(error);
                    }
                }
            };

            if (param.async) {
                req.async = param.async;
            }

            if (param.cache) {
                req.cache = param.cache;
            }

            if (param.data) {
                req.data = param.data;
            }

            $.ajax(req);
        } catch (e) {
            if (typeof param.onError === 'function') {
                param.onError({});
            }
        }
    };

    return {
        sendAjaxRequest: sendAjaxRequest
    };
})();
