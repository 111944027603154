import {React, PublicTemplate, useActionUrl, $, useEffect, showUserMessage, showMessage} from 'library/base/baseContainerImports';
import MainForm from "configs/Form/Admin/User/ForgottenPassword";

const Container = () => {

    const {getFormActions} = useActionUrl();
    const {formAction, formDefinitionAction} = getFormActions(null, {});

    useEffect( () => {
        $("#forgotten").click(function(){
            $(".form_data").processForm({mode : "return"});
            return false;
        });
    }, []);

    return (
        <PublicTemplate
            formAction={formAction}
            formDefinitionAction={formDefinitionAction}
            mainForm={MainForm}
            title={'Forgotten Your Password'}
            subTitle={'We will send you an email with instructions on how to reset your password.'}
            isRequestFailed={false}
            errorMessage={'We could not send forgot password request. Please try again.'}
        />
    );
};

export default Container;
