import $ from "jquery";
import Pace from 'pace-js';
import 'uniforms';


const initBase = () => {
	$(function () {

		Pace.on('done', function () {
			$(".page-loader").hide();
		});

		$(".table-body").niceScroll({autohidemode: false});


		$(function () {

			function addValidationMessage(object, response) {

				var parent = object.closest('.form-group');
				if (!$(parent).find(".validation-error-label").length) {
					$(parent).append('<label class="validation-error-label" for="with_icon"></label>');
				}

				if (response.code == 0) {
					$(parent).find(".validation-error-label").removeClass('validation-valid-label');
				} else {
					$(parent).find(".validation-error-label").addClass('validation-valid-label');
				}

				$(parent).find(".validation-error-label").text(response.message);

			}

			function showMessage(object, message, mobile_only) {

				object.find('.nNote').remove();

				if (mobile_only) {
					object.prepend("<div class='nNote nWarning' style='display:none;'><p>" + message + "</p></div>");
				} else {
					object.prepend("<div class='nNote nWarning' style='display:none;'><p>" + message + "</p></div>");
				}

				object.find('.nNote').slideDown();
			}


			$.fn.Validate = function (p) {		//Assigns relevant class information to dynamically validated field

				if (typeof p.event == "undefined") {

					if ($(this).val() != '') {
						addValidationMessage($(this), p.validate());
					}

				} else {

					$(this).on(p.event, function () {
						if ($(this).val() != '') {
							console.log($(this));
							addValidationMessage($(this), p.validate());
						}
					});
				}
			}


			// head.js(
			// 	{ FormJS: BASE + "/min/?g=form_js" },
			// 	function(){
			//
			// 	}
			// );
			//
			// head.js(
			// 	{ Application: BASE + "theme/js/system/application.js"  }
			// );
			//
			//
			//
			// head.js(
			// 	{ doT: BASE + "theme/js/system/doT.min.js" }
			// );
			//
			//
			// head.js(
			// 	{ JsonRpc: BASE + "theme/js/system/jquery.zend.jsonrpc.js" },
			// 	function(){
			//
			// 		var API;
			// 		var APIASync;
			//
			// 		window.API = jQuery.Zend.jsonrpc({
			// 			url: BASE + 'api/v1/rpc',
			// 			exceptionHandler: function(obj){
			// 				if (obj.message != undefined) {
			// 					if (obj.message == 'Unauthorized') {
			// 						window.location = BASE;
			// 					}
			// 				}
			// 			}
			// 		});
			//
			// 		window.APIASync = jQuery.Zend.jsonrpc({url: BASE + 'api/v1/rpc',
			// 			async: true,
			// 			exceptionHandler: function(obj){
			// 				if (obj.message != undefined) {
			// 					if (obj.message == 'Unauthorized') {
			// 						window.location = BASE;
			// 					}
			// 				}
			// 			}
			// 		});
			//
			//
			// 		//Create heart-beat function
			// 		function keepAlive() {
			// 			window.setInterval(function () {
			// 				window.APIASync.cart.keepAlive({success:function (result) {
			// 					return result;
			// 				}});
			// 			}, 300000); // repeat forever, polling every 5 minutes
			// 		}
			//
			// 		window.onload = function start() {
			// 			keepAlive();
			// 		}
			// 	}
			// );


		});

	});
};

export default initBase;



