import  {
    React, useLocation, AdminRoutes
}  from 'library/base/baseContainerImports';
import LeftMenuTopBarLayout from "./LeftMenuTopBarLayout";
import SignupLayout from "./SignupLayout";

const signupLayoutUrls = [
    AdminRoutes.ADMIN.SIGNUP.path ,
    AdminRoutes.ADMIN.SIGNUP.path + "/" ,
];

const LayoutContainer = () => {
    const location = useLocation();
    if(signupLayoutUrls.indexOf(location.pathname) !== -1){
        return <SignupLayout/>;
    }else{
        return <LeftMenuTopBarLayout/>;
    }
};

export default LayoutContainer;
