/* eslint-disable */
import React from "react";

const InputText = ({id, label, options, required, type, value}) => {

    const getInputAttributes = () => {
        let attributes = {
            type: type,
            id: id,
            name: id,
            defaultValue: value,
        };

        if (required) {
            attributes['data-msg-required'] = '(Required)';
        }

        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                attributes[key] = options[key];

            }
        }

        return attributes;
    };

    return (
        <>
            <label for={id}>
                {label}
                {options.help && <span className="help-block">{options.help}</span>}
                {required && <span className="text-danger">&nbsp;*</span>}
            </label>

            <input {...getInputAttributes()}/>
        </>
    );
};

export default InputText;
