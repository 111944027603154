import React, {useEffect} from "react";
import './imports/dateTimeImports';
import '../../../../css/cssImports';
import useChildElements from '../../../hooks/useChildElements';
import initPickerDate from '../../../../theme/js/pages/picker_date.js';

const InputDateTime = ({id, label, options, required, type, value, children}) => {

    useEffect(() => {
        initPickerDate();
    }, []);

    return (
        <>
            <label for={id}>
                {label}
                {options.help && <span className="help-block">{options.help}</span>}
                {required && <span className="text-danger">&nbsp;*</span>}
            </label>

            {useChildElements(children)}
        </>
    );
};

export default InputDateTime;
