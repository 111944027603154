import 'jquery';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-select'
import 'datatables.net-select-dt'
import 'datatables.net-buttons';
import 'datatables.net-buttons-dt';
import "select2";
import 'jquery.nicescroll';
