import React from "react";

const AdminAccountInvoker = () => {
    return (
        <li>
            <a href="/admin/my-account">
                <i className="icon-user"/>
                <span className="inline-block position-right ml-10">My Account</span>
            </a>
        </li>
    );
};

export default AdminAccountInvoker;
