import React from "react";
import useChildElements from '../../../hooks/useChildElements';

const InputRadio = ({id, label, options, required, children}) => {
    return (
        <>
            <label for={id}>
                {label}
                {options.help && <span className="help-block">{options.help}</span>}
                {required && <span className="text-danger">&nbsp;*</span>}
            </label>

            {useChildElements(children)}
        </>
    );
};

export default InputRadio;
