import React from "react";
import {getRouteForModule, getRouteObject} from './adminRouteUtils';
import {getPlayerRouteForModule, getPlayerRouteObject} from './playerRouteUtils';

/**
 * Admin route configuration
 *
 * Use getRouteForModule to create routes for all operations including main container
 * Use getRouteObject to get a single route object
 *
 * Check above method signatures in `adminRouteUtils` for more information
 */
export const AdminRoutes = {
    "ADMIN": {
        ...getRouteObject('', '', '', 'Dashboard','icon-home'),
        "STATS": getRouteObject('stats', '', '', 'Dashboard','icon-home'),
        "VIDEOS": getRouteObject('videos', '', '', 'Dashboard','icon-home'),
        "MY_ACCOUNT": {
            ...getRouteForModule('my-account', 'User/MyAccount', 'My Account', '','icon-office'),
            "MY_PHOTO": {
                ...getRouteObject('my-photo', null, 'User/MyAccount', 'My Profile Photo'),
                "UPLOAD": getRouteObject('my-photo/upload', null, 'User/MyAccount', 'My Photo Upload'),
                "DELETE": getRouteObject('my-photo/delete', null, 'User/MyAccount', 'My Photo Delete'),
            },
        },
        "DRIVER": {
            ...getRouteForModule('driver', 'Driver', 'Drivers', 'Driver','icon-steering-wheel'),
            "PHOTO": {
                ...getRouteObject('driver/photo', null, 'Driver', 'Driver Photo'),
                "UPLOAD": getRouteObject('driver/photo/upload', null, 'Driver', 'Driver Photo Upload'),
                "DELETE": getRouteObject('driver/photo/delete', null, 'Driver', 'Driver Photo Delete'),
            },
        },
        "SERIES": {
            ...getRouteForModule('series', 'Series', 'Series', 'Series', 'icon-flag3'),
            "PRIZE": {
                ...getRouteForModule('series/prize', 'Series/Prize', 'Prizes', 'Prize'),
                "PHOTO": {
                    ...getRouteObject('series/prize/photo', null, 'Series/Prize', 'Prize Photo'),
                    "UPLOAD": getRouteObject('series/prize/photo/upload', null, 'Series/Prize', 'Prize Photo Upload'),
                    "DELETE": getRouteObject('series/prize/photo/delete', null, 'Series/Prize', 'Prize Photo Delete'),
                },
            },
            "ROUND": getRouteForModule('series/round', 'Series/Round', 'Rounds', 'Round'),
            "ROUND_POINTS": getRouteForModule('series/round/points', 'Series/RoundPoints', 'Round Points', 'Round Point'),
            "DRIVERS": getRouteForModule('series/driver', 'Series/Drivers', 'Series Drivers', 'Series Drivers'),
        },
        "INFO": getRouteForModule('generic-info', 'Info', 'Info', 'Info','icon-info22'),
        "ANNOUNCEMENT": getRouteForModule('announcement', 'Announcement', 'Announcements', 'Announcement','icon-bell2'),
        "RESULTS": {
            "CALCULATE": {
                ...getRouteObject('results/calculate', null, 'Results', 'Calculate Results'),
            },
            ...getRouteForModule('results', 'Results', 'Results', 'Results','icon-finish'),
            "SUMMARY": getRouteForModule('results/summary', 'Results/Summary', 'Summary', 'Summary'),
            "RANKING": getRouteForModule('results/ranking', 'Results/Ranking', 'Position and Handicap', 'Position and Handicap'),
            "RACE": getRouteForModule('results/race', 'Results/Race', 'Race Results', 'Race Results'),
        },
        "SPONSORS":{
            ...getRouteForModule('sponsors', 'Sponsors', 'Sponsors', 'Sponsors','icon-gift'),
            "PHOTO": {
                ...getRouteObject('sponsors/photo', null, 'Sponsors', 'Sponsors Photo'),
                "UPLOAD": getRouteObject('sponsors/photo/upload', null, 'Sponsors', 'Sponsors Photo Upload'),
                "DELETE": getRouteObject('sponsors/photo/delete', null, 'Sponsors', 'Sponsors Photo Delete'),
            },
        },
        "USERS": getRouteForModule('users', 'User', 'Users', 'User','icon-users'),
        "SIGNUP": getRouteObject('signup', null, 'Signup', 'Signup'),
    },
    "PLAYER" : {
        "TEAM": {
            ...getPlayerRouteObject('team', null, 'Team', 'My Team'),
            "SET_TEAM": getPlayerRouteObject('team/set-my-team', null, 'Team', 'Set My Team'),
            "SET_POLL_POSITION": getPlayerRouteObject('team/set-my-poll-position', null, 'Team', 'Set My Pole Position'),
            "UNSET_TEAM": getPlayerRouteObject('team/unset-my-team', null, 'Team', 'Unset My Team'),
            "UNSET_POLL_POSITION": getPlayerRouteObject('team/unset-my-poll-position', null, 'Team', 'Unset My Pole Position'),
        },

        "HELP_INFO": {
            "FAQ": getPlayerRouteObject('help-info/faq', null, 'HelpInfo/Faq', 'FAQ', 'Faq','icon-info22'),
            "RULES": getPlayerRouteObject('help-info/rules', null, 'HelpInfo/Rules', 'Rules', 'Rules','icon-info22'),
            "TERMS": getPlayerRouteObject('help-info/terms', null, 'HelpInfo/Terms', 'Terms', 'Terms','icon-info22'),
            "PRIVACY": getPlayerRouteObject('help-info/privacy', null, 'HelpInfo/Privacy', 'Privacy', 'Privacy','icon-info22'),
            "CONTACT": getPlayerRouteObject('help-info/contact', null, 'HelpInfo/Contact', 'Contact', 'Contact','icon-info22'),
        },
        "LEAGUE": {
            ...getPlayerRouteForModule('leagues', 'League', 'Leagues', 'League','icon-people'),
            "ALLOWED": getPlayerRouteObject('leagues/allowed', null, 'League', 'League', 'League','icon-people'),
            "SUMMARY": getPlayerRouteObject('leagues/summary', null, 'League', 'League', 'League','icon-people'),
            "PHOTO": {
                ...getPlayerRouteForModule('leagues/photo', null, 'League', 'League Photo'),
                "UPLOAD": getPlayerRouteForModule('leagues/photo/upload', null, 'League', 'League Photo Upload'),
                "DELETE": getPlayerRouteForModule('leagues/photo/delete', null, 'League', 'League Photo Delete'),
            },
            "MY_LEAGUES": getPlayerRouteObject('leagues/my-leagues', null, 'Leagues', 'My Leagues'),
            "SEND_AN_INVITATION": getPlayerRouteObject('leagues/invite', null, 'Leagues', 'Send an invitation'),
            "MEMBERS": {
                ...getPlayerRouteForModule('leagues/members', 'League/Members', 'League Members', 'League Member','icon-people'),
            }
        },
        "PRIZE": {
            ...getPlayerRouteObject('prize', null, 'Prize', 'Prize'),
        },
        "ANNOUNCEMENT": {
            ...getPlayerRouteObject('announcement', null, 'Announcement', 'Announcements'),
        },
        "RESULTS": {
            ...getPlayerRouteObject('results', null, 'Results', 'Championship'),
            "FILTER": {
                "SERIES": getPlayerRouteObject('results/filter/series', null, 'Results', 'Results'),
                "ROUNDS": getPlayerRouteObject('results/filter/rounds', null, 'Results', 'Results'),
            },
            "SUMMARY": getPlayerRouteObject('results/summary', null, 'Results/Summary', 'Results'),
            "ROUND": {
                ...getPlayerRouteObject('results/rounds', null, 'Results/Round', 'Results'),
                "DRIVERS": getPlayerRouteObject('results/rounds/drivers', null, 'Results/Round/Drivers', 'Driver Results'),
                "STATS": getPlayerRouteObject('results/rounds/stats', null, 'Results/Round/Drivers', 'Driver Results'),
            },
            "ROUND_NAME": getPlayerRouteObject('results/round-name', null, 'Results/Round', 'Results'),
            "MINE": getPlayerRouteObject('results/mine', null, 'Results/Mine', 'My Results'),
            "PLAYER": getPlayerRouteObject('results/player', null, 'Results/Player', 'Player Results'),
            "DRIVERS": getPlayerRouteObject('results/drivers', null, 'Results/Mine', 'My Results'),
        },
    }
};