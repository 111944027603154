import React, {useEffect} from 'react';
import {
    $,
    AdminRoutes,
    queryString,
    Timer,
    urlConfigs,
    useActionUrl,
    useLocation
} from 'library/base/baseContainerImports';

const CountdownTimer = ({timerTitle, secondsToGo}) => {

    const location = useLocation();
    const {refresh} = queryString.parse(location.search);
    if (refresh == undefined) {
        window.location = location.pathname + '?refresh=1';
    }

    useEffect(() => {
        let timer = new Timer();
        timer.start({countdown: true, precision: 'seconds', startValues: {seconds: secondsToGo}});
        timer.addEventListener('secondsUpdated', function (e) {
            $(".timer-days").text(timer.getTimeValues().days);
            $(".timer-hours").text(timer.getTimeValues().hours);
            $(".timer-mins").text(timer.getTimeValues().minutes);
            $(".timer-secs").text(timer.getTimeValues().seconds);
        });
        timer.addEventListener('targetAchieved', function (e) {
            window.location.reload();
        });
    }, []);

    return (
        <>
            <div className="heading-elements">
                <div className="heading-btn-group">
                    <div className="pull-left mr-10">
                        <h6 className="text-bold text-uppercase text-danger-600">{timerTitle}</h6>
                    </div>
                    <div className="btn btn-link btn-float has-text">
                        <h5 className="no-margin text-bold text-size-large text-center timer-days"></h5>
                        <h6 className="no-margin no-padding-top text-bold text-uppercase">Days</h6>
                    </div>
                    <div className="btn btn-link btn-float has-text">
                        <h5 className="no-margin text-bold text-size-large text-center timer-hours"></h5>
                        <h6 className="no-margin no-padding-top text-bold text-uppercase">Hours</h6>
                    </div>
                    <div className="btn btn-link btn-float has-text">
                        <h5 className="no-margin text-bold text-size-large text-center timer-mins"></h5>
                        <h6 className="no-margin no-padding-top text-bold text-uppercase">Mins</h6>
                    </div>
                    <div className="btn btn-link btn-float has-text">
                        <h5 className="no-margin text-bold text-size-large text-center timer-secs"></h5>
                        <h6 className="no-margin no-padding-top text-bold text-uppercase">Secs</h6>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CountdownTimer;
