/* eslint-disable */
import React, {useEffect} from 'react';
import '../jsImports';
import initApplicationDefaults from "../../theme/js/system/application";
import initBase from "../../theme/js/system/base";

const useInitialize = () => {

    useEffect(() => {
        initApplicationDefaults();
        initBase();
    }, []);

    return true;
};

export default useInitialize;
