/* eslint-disable */
import React from 'react';
import each from 'foreach-object';
import {urlConfigs} from "../../configs/config";
import {useLocation} from "react-router";

const useActionUrl = () => {
    const baseUrl = urlConfigs.BASE_URL;
    const location = useLocation();

    const getFullUrl = (relativePath, queryParams) => {
        let queryString = '';

        if (queryParams) {
            queryString = '?';

            each(queryParams, (value, key) => {
                if (value != undefined) {
                    queryString = queryString + key + '=' + value + '&';
                }
            });
        }

        return baseUrl + relativePath + queryString;
    };

    const getFormActions = (relPath, queryParams) => {
        let relativePath = relPath ? relPath : location.pathname;

        const formAction = getFullUrl(relativePath, queryParams);
        const formDefinitionAction = formAction + 'definition=form';

        return {
            formAction,
            formDefinitionAction
        };
    };

    return {
        getFullUrl,
        getFormActions
    }
};

export default useActionUrl;
