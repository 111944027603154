/* eslint-disable */
import React, {useEffect, useState} from 'react';
import './elements/imports/formImports';
import initUploaderBootstrap from '../../../theme/js/pages/uploader_bootstrap.js';

import useChildElements from '../../hooks/useChildElements';
import useInitDatatable from "theme/js/pages/datatables_responsive";
import initDatatableSelect from "theme/js/pages/datatables_extension_select";
import useAjaxSetup from "../../hooks/useAjaxSetup";
import {initDataMask} from "../../../theme/js/core/jasny-bootstrap";
import {getInfo} from "../../../service/request-handler";

const ModalForm = ({encType, action, formClass, jsonArray, jsonLoadAction, jsonLoadActionHandler, modalId, title, saveCaption, closeCaption, id, saveAction}) => {

    const [jsonApiArray, setJsonApiArray] = useState();
    const {prepareAjaxSetup} = useAjaxSetup();
    useInitDatatable();

    useEffect(() => {
        // Initialise default
        initUploaderBootstrap();
        initDataMask();
        initDatatableSelect();
        prepareAjaxSetup();

        if (jsonLoadAction) {
            getInfo(jsonLoadAction, async function (data) {
                await setJsonApiArray(data);

                if (typeof jsonLoadActionHandler === "function") {
                    jsonLoadActionHandler();
                }
                handleUpdate();
            }, () => alert('Something went wrong. Could not load requested data.'));
        }
    }, []);
    const renderer = () => {
        // Left room for additional functionality
        return useChildElements(jsonApiArray ? jsonApiArray : jsonArray);
    };

    const handleUpdate = () => {
        $("#" + modalId + " .modal_submit").click(function(){
            saveAction();
            return false;
        });
    };

    return (

            <div id={modalId} className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h5 className="modal-title">{title}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form encType={encType} action={action} id={id}
                                      className={formClass} method="post">
                                    <dl className="zend_form">
                                        {renderer()}
                                    </dl>
                                </form>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="modal-footer">
                            <div className="action-bar pull-right">
                                <button type="button" className="btn btn-primary modal_submit">{saveCaption}</button>
                                <button type="button" className="btn btn-link model_close" data-dismiss="modal">{closeCaption}</button>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default ModalForm;
