import {React} from 'library/base/baseContainerImports';

const OtherSponsors = (props) => {

    const sponsors = props.list;

    return (
        <div>
            <p className="text-semibold">Brought to you by our sponsors</p>
            <div className="carousel slide other-sponsors-carousel" data-ride="carousel" data-interval="5000">
                <div className="carousel-inner" role="listbox">
                    {sponsors.map((sponsor, index) => {
                        let active = '';
                        if (index === 1) {
                            active = 'active';
                        }
                        return (
                            <div className={'item ' + active}>
                                <a target="_blank" href={sponsor.url}>
                                    <img src={sponsor.photo} alt=""/>
                                </a>
                            </div>
                        );

                    })}

                </div>
            </div>

        </div>
    );
};

export default OtherSponsors;
