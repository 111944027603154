import React from "react";
import {PageHeader} from "../library/base/baseContainerImports";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div>
                <PageHeader />
                <div className="content">
                    <div className="panel panel-flat">
                        <div className="alert alert-danger alert-styled-left alert-bordered no-margin-bottom" >
                            <p><strong>We have encountered a technical issue. Please logout and log back in. If the problem persists, please contact us.</strong></p>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
