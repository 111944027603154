import React, {Suspense} from "react";
import {RenderRoutes, useAppRoutes} from "../../routes/routes";
import ErrorBoundary from "../../app/ErrorBoundry";

const Content = () => {
    const {getRoutes} = useAppRoutes();
    const ROUTES = getRoutes();
    return (
        <div className="content-wrapper">
            <ErrorBoundary>
                <Suspense fallback={<div>  </div>}>
                    <RenderRoutes routes={ROUTES}/>
                </Suspense>
            </ErrorBoundary>
        </div>
    );
};

export default Content;
