import React from "react";
import {camelCase, startCase} from 'lodash';

/**
 * This creates complete routing object for a module.
 * Creates Outer container (Customers), add page (Add Customer), edit page (Edit Customer) and etc.
 * This method can be used even if all the sub pages do not exist.
 * If there is no corresponding page found, default Error page will be shown
 *
 * @param routeName - this should be the path after ROUTE_PREFIX. Ex: customer, user/my-account
 * @param folderName - Main Folder name or folder path from Admin/. Case sensitive Ex: Customer, User/MyAccount
 * @param title - Title of the page header of outer Container. Ex: Customers
 * @param subTitle - Suffix of the child page title. Ex: Customer. This will be used as 'Add' + 'Customer, 'Edit' + 'Customer'
 * @param headerIcon - This is used to construct page header link. Ex: icon-users4
 */
export const getPlayerRouteForModule = (routeName, folderName, title, subTitle, headerIcon) => {
    let routeModuleObject = getPlayerRouteObject(routeName, null, folderName, title);

    routeModuleObject["ADD"] = getPlayerRouteObject(routeName, 'add', folderName, title, subTitle, headerIcon);
    routeModuleObject["EDIT"] = getPlayerRouteObject(routeName, 'edit', folderName, title, subTitle, headerIcon);
    routeModuleObject["DELETE"] = getPlayerRouteObject(routeName, 'delete', folderName, title, subTitle, headerIcon);
    routeModuleObject["VIEW"] = getPlayerRouteObject(routeName, 'view', folderName, title, subTitle, headerIcon);
    routeModuleObject["META"] = getPlayerRouteObject(routeName, 'meta', folderName, title, subTitle, headerIcon);
    routeModuleObject["DISPATCH"] = getPlayerRouteObject(routeName, 'dispatch', folderName, title, subTitle, headerIcon);

    return routeModuleObject;
};

const ROUTE_PREFIX_PLAYER = '/player/';

/**
 * This returns a single route object
 * Ex: {
 *             "path": '/player/customers/add',
 *            "title": 'Add Customer',
 *           "pageHeaderLinks": [{title: 'Customers', url: '/player/customers', icon: 'icon-users4'}],
 *          "container": CustomerAddContainer,
 *     }
 *
 * @param routeName - this should be the path after ROUTE_PREFIX. Ex: customer, user/my-account
 * @param subPath - if it is not the outer container, then specify the sub path in lowercase. Ex: add
 * @param folderName - Main Folder name or folder path from Admin/. Case sensitive Ex: Customer, User/MyAccount
 * @param title - Title of the page header of outer Container. Ex: Customers
 * @param subTitle - Suffix of the child page title. Ex: Customer. This will be used as 'Add' + 'Customer, 'Edit' + 'Customer'
 * @param headerIcon - This is used to construct page header link. Ex: icon-users4
 */
export const getPlayerRouteObject = (routeName, subPath, folderName, title, subTitle, headerIcon) => {
    let routeObject = {};
    const pascalSubPath = subPath ? startCase(camelCase(subPath)).replace(/ /g, '') : '';
    const subPathSuffix = subPath ? '/' + subPath : '';

    routeObject['path'] = ROUTE_PREFIX_PLAYER + (routeName ? routeName : '') + subPathSuffix;
    routeObject['title'] = pascalSubPath ? pascalSubPath + ' ' + subTitle : title;

    const importPath = (folderName ? folderName + '/' : '') + (pascalSubPath ? pascalSubPath + '/' : '') + 'Container';
    routeObject['container'] = React.lazy(() => import('modules/Player/' + importPath));

    if (headerIcon) {
        routeObject['pageHeaderLinks'] = [{title: title, url: (ROUTE_PREFIX_PLAYER + routeName), icon: headerIcon}];
    }

    return routeObject;
};
