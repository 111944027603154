/* eslint-disable */
import React from 'react';
import $ from 'jquery';
import each from 'foreach-object';
import populate from "populate.js";

const usePopulate = (formId) => {

    const populateForm = (data) => {
        const formElement = document.getElementById(formId);
        if (formElement) {
            populate(formElement, data);
        }

        each(data, (value, key) => {
            const element = formElement.elements.namedItem(key);
            if (!element) {
                return false;
            }

            const type = element.type || element[0].type;

            switch (type) {
                case 'select':
                case 'select-one':
                    $('[name=' + key + ']').val(value).trigger('change');
                    break;
                case 'select-multiple':
                    const container = $('.multiselect-container');
                    container.find(":checkbox").each(function () { // Deselect previous selection
                        if ($(this).prop('checked') === true) {
                            $(this).click();
                            $(this).prop('checked', false);
                        }
                    });
                    container.find(":checkbox").each(function () { // Select new selection
                        if ($.inArray(this.value, value) !== -1) {
                            $(this).click();
                            $(this).prop('checked', true);
                        }
                    });

                    const selectElement = $('[name=' + key + ']');
                    selectElement.val(value).multiselect('updateButtonText');
                    selectElement.val(value).multiselect("refresh");
                    break;

                case 'text':
                    //if (key === 'start_date' || key === 'end_date') {
                    //    const element = $('[name=' + key + ']');
                    //    element.data('daterangepicker').setStartDate(value);
                    //    element.data('daterangepicker').setEndDate(value);
                    //}
                    break;
                default:
                    break;
            }
        });

    };

    return populateForm;
};

export default usePopulate;
