import React from "react";
import useChildElements from '../../../hooks/useChildElements';

const InputCheckbox = ({id, label, options, required, children}) => {

    const getInputAttributes = () => {
        let attributes = {
            type: 'checkbox',
            id: id,
            name: id,
            defaultValue: "1",
        };

        if (required) {
            attributes['data-msg-required'] = '(Required)';
        }

        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                attributes[key] = options[key];

            }
        }

        return attributes;
    };

    return (
        <>
            <label for={id}>
                {label}
                {options.help && <span className="help-block">{options.help}</span>}
                {required && <span className="text-danger">&nbsp;*</span>}
            </label>

            <input {...getInputAttributes()}/>
        </>
    );
};

export default InputCheckbox;
