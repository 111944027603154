import React, { useEffect } from 'react';
import './imports/formImports';

const InputLocation = ({id, label, options, required, type, value, auto_value, typeahead_id}) => {
    useEffect(() => {

    }, []);

    const getInputAttributes = () => {
        let attributes = {
            type: type,
            id: typeahead_id + '_value',
            name: typeahead_id + '_value',
            defaultValue: auto_value,
        };

        if (required) {
            attributes['data-msg-required'] = '(Required)';
        }

        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                attributes[key] = options[key];

            }
        }

        return attributes;
    };

    const getHiddenInputAttributes = () => {
        return {
            type: 'hidden',
            id: id,
            name: id,
            value: value,
            className: typeahead_id + '_value_source',
        };
    };

    return (
        <>
            <label for={id}>
                {label}
                {options.help && <span className="help-block">{options.help}</span>}
                {required && <span className="text-danger">&nbsp;*</span>}
            </label>

            <input {...getInputAttributes()}/>
            <input {...getHiddenInputAttributes()}/>
        </>
    );
};

export default InputLocation;
