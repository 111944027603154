import React, {useEffect} from "react";
import useChildElements from '../../../hooks/useChildElements';
import "select2";
import initApplicationDefaults from "../../../../theme/js/system/application";

const InputSelect = ({id, name, label, options, required, children}) => {

    const getInputAttributes = () => {
        let attributes = {
            id: id,
            name: name ? name : id,
        };

        if (required) {
            attributes['data-msg-required'] = '(Required)';
        }

        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                attributes[key] = options[key];

            }
        }

        if (!attributes.className) {
            attributes.className = 'select';
        }

        return attributes;
    };

    useEffect(() => {
        initApplicationDefaults();
    }, []);

    return (
        <>
            <label for={id}>
                {label}
                {options.help && <span className="help-block">{options.help}</span>}
                {required && <span className="text-danger">&nbsp;*</span>}
            </label>
            <select {...getInputAttributes()}>
                {useChildElements(children)}
            </select>
        </>
    );
};

export default InputSelect;
