import {React} from 'library/base/baseContainerImports';

const MainSponsors = (props) => {

    return (
        <>
            <p className="text-semibold">Presented By</p>
            <a target="_blank" href={props.main.url}>
                <img  src={props.main.photo} alt=""/>
            </a>

        </>
    );
};

export default MainSponsors;
