/* eslint-disable */
import React from 'react';
import each from 'foreach-array';
import InputDateTime from '../basic/forms/elements/InputDateTime';
import InputInlineText from '../basic/forms/elements/InputInlineText';
import InputLocation from '../basic/forms/elements/InputLocation';
import InputRadio from '../basic/forms/elements/InputRadio';
import InputCheckbox from '../basic/forms/elements/InputCheckbox';
import InputSelect from '../basic/forms/elements/InputSelect';
import InputText from '../basic/forms/elements/InputText';
import InputTextArea from '../basic/forms/elements/InputTextArea';

const componentMap = {
    InputDateTime: InputDateTime,
    InputInlineText: InputInlineText,
    InputLocation: InputLocation,
    InputRadio: InputRadio,
    InputCheckbox: InputCheckbox,
    InputSelect: InputSelect,
    InputText: InputText,
    InputTextArea: InputTextArea,
};

const useChildElements = (childArray) => {
    const elementArray = [];

    if (childArray && childArray.length > 0) {
        Array.from(childArray, child => {
            for (const key of Object.keys(child)) {
                if (child.hasOwnProperty(key)) {
                    if (!child[key].isComponent) {
                        const staticContent = child[key].static;
                        const childContent = child[key].children ? useChildElements(child[key].children) : null;
                        const elementContent = staticContent ? [staticContent, childContent] : childContent;
                        let attributes = child[key].attributes;

                        if (attributes && attributes.style) {
                            attributes.style = getStyleObject(attributes.style);
                        }

                        elementArray.push(React.createElement(key, attributes, elementContent));
                    } else {
                        const CustomTag = componentMap[key];
                        let attributes = child[key].attributes;
                        attributes.children = child[key].children;
                        attributes.staticContent = child[key].static;
                        elementArray.push(<CustomTag {...attributes}/>)
                    }
                }
            }
        });
    }
    return elementArray;
};

export default useChildElements;

const getStyleObject = (styleString) => {
    const styles = {};
    if (styleString && typeof styleString === 'string') {
        const trimmedStyleString = styleString.trim();
        const keyValueArray = trimmedStyleString.split(';');

        each(keyValueArray, (keyValue) => {
            if (keyValue) {
                const values = keyValue.split(":");

                if (values && values.length === 2) {
                    styles[values[0]] = values[1];  // assigning style key value pairs
                }
            }
        })
    }
    return styles;
};
