import React from 'react';
import Content from "./layoutComponents/SignupContent";

const SignupLayout = () => {

    return (
        <div>
            <div className="page-container">
                <div className="page-content">
                    <Content/>
                </div>
            </div>
        </div>
    );
};

export default SignupLayout;
