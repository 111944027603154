/* ------------------------------------------------------------------------------
*
*  # Responsive extension for Datatables
*
*  Specific JS code additions for datatable_responsive.html page
*
*  Version: 1.0
*  Latest update: Aug 1, 2015
*
* ---------------------------------------------------------------------------- */
import React, {useEffect} from 'react';
import $ from 'jquery';
import {useHistory} from "react-router-dom";

/**
 * Code inside useEffect function is legacy jquery code
 * Converted this to a react hook to make use of `useHistory` hook to navigate
 *
 * @returns {boolean}
 */
const useInitDatatable = () => {
    const history = useHistory();

    useEffect(() => {
        $(function() {

            // Table setup
            // ------------------------------

            // Setting datatable defaults
            $.extend( $.fn.dataTable.defaults, {
                autoWidth: false,
                responsive: true,
                serverSide: true,
                columnDefs: [{
                    orderable: false,
                    width: '100px',
                    targets: [ 5 ]
                }],
                dom: '<"datatable-header"fl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
                language: {
                    search: '<span>Filter:</span> _INPUT_',
                    lengthMenu: '<span>Show:</span> _MENU_',
                    paginate: { 'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;' }
                },
                drawCallback: function () {
                    $(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
                },
                preDrawCallback: function() {
                    $(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
                }
            });




            // Table setup for buttons
            // ------------------------------

            // Setting datatable defaults
            $.extend( $.fn.dataTable.defaults, {
                autoWidth: false,
                dom: '<"datatable-header"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
                language: {
                    search: '<span>Filter:</span> _INPUT_',
                    lengthMenu: '<span>Show:</span> _MENU_',
                    paginate: { 'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;' }
                }
            });



            // Basic responsive configuration
            $('.datatable-responsive').DataTable();


            // Column controlled child rows
            $('.datatable-responsive-column-controlled').DataTable({
                responsive: {
                    details: {
                        type: 'column'
                    }
                },
                columnDefs: [
                    {
                        className: 'control',
                        orderable: false,
                        targets:   0
                    },
                    {
                        width: "100px",
                        targets: [6]
                    },
                    {
                        orderable: false,
                        targets: [6]
                    }
                ],
                order: [1, 'asc']
            });


            // Control position
            $('.datatable-responsive-control-right').DataTable({
                responsive: {
                    details: {
                        type: 'column',
                        target: -1
                    }
                },
                columnDefs: [
                    {
                        className: 'control',
                        orderable: false,
                        targets: -1
                    },
                    {
                        width: "100px",
                        targets: [5]
                    },
                    {
                        orderable: false,
                        targets: [5]
                    }
                ]
            });


            // Whole row as a control
            $('.datatable-responsive-row-control').DataTable({
                responsive: {
                    details: {
                        type: 'column',
                        target: 'tr'
                    }
                },
                columnDefs: [
                    {
                        className: 'control',
                        orderable: false,
                        targets:   0
                    },
                    {
                        width: "100px",
                        targets: [6]
                    },
                    {
                        orderable: false,
                        targets: [6]
                    }
                ],
                order: [1, 'asc']
            });





            /**
             * Xtreme Data Tables
             */

            $('.datatable-xtreme').each(function(i,v){

                var buttons = $(v).data('button-config');

                if (buttons !== '') {
                    $(buttons).each(function (j, k) {
                        k.action = function (e, dt, node, config) {
                            if (k.link) {
                                history.push('/' + k.link);
                            }
                        };
                    });
                } else {
                    buttons = [];
                }

                $(v).on( 'draw.dt', function () {

                    $(".table-responsive").niceScroll({autohidemode:false});
                    $(".styled").uniform({ radioClass: 'choice' });
                    $(".datatable-xtreme .icons-list a").unbind('click').click(function(){
                        if ($(this).data('noajax') == undefined) {
                            if (window.confirm("Are you sure to " + $(this).attr('title') + "?")){
                                var $this = $(this);
                                $.post($(this).attr('href') , {} , function(){
                                    $this.closest('.datatable-xtreme').DataTable().ajax.reload();
                                    return false;
                                });
                            }
                        }else{
                            window.location = $(this).attr('href');
                        }

                        return false;
                    });
                }).on( 'responsive-display.dt', function ( e, datatable, row, showHide, update ) {
                    $(".datatable-xtreme .icons-list a").unbind('click').click(function(){
                        if ($(this).data('noajax') == undefined) {
                            if (window.confirm("Are you sure to " + $(this).attr('title') + "?")){
                                var $this = $(this);
                                $.post($(this).attr('href') , {} , function(){
                                    $this.closest('.datatable-xtreme').DataTable().ajax.reload();
                                    return false;
                                });
                            }
                        }else{
                            window.location = $(this).attr('href');
                        }

                        return false;
                    });
                }).DataTable({
                    retrieve: true,
                    responsive: {
                        details: {
                            type: 'column',
                            target: 'tr'
                        }
                    },
                    columnDefs: [
                        {
                            className: 'control',
                            orderable: false,
                            targets:   0
                        },
                    ],
                    buttons: buttons,
                    pageLength: 50
                });

            });

            $.fn.dataTable.ext.errMode = function ( settings, helpPage, message ) {
                console.error(message);
            };


            // External table additions
            // ------------------------------

            // Add placeholder to the datatable filter option
            $('.dataTables_filter input[type=search]').attr('placeholder','Type to filter...');


            // Enable Select2 select for the length option
            $('.dataTables_length select').select2({
                minimumResultsForSearch: Infinity,
                width: 'auto'
            });

        });
    }, []);
};

export default useInitDatatable;
