/* eslint-disable */
import React from 'react';
import $ from 'jquery';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {authLogout} from "../../modules/Admin/User/Login/store/actions";

const useAjaxSetup = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const prepareAjaxSetup = (token) => {

        $.ajaxSetup({
            beforeSend: (xhr) => {
                const userToken = token ? token : localStorage.getItem('userToken');
                if (userToken) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + userToken);
                } else {
                    xhr.setRequestHeader('Authorization', 'Bearer Public');
                }
            },
            complete: function (xhr) {
                if (xhr.status === 403) {   // Token is expired
                    dispatch(authLogout());
                    localStorage.setItem('userToken', '');
                    localStorage.setItem('loginStatus', 'loggedOut');
                    history.replace("/admin/login");
                }
            }
        });

        $(document).ajaxStop(function(){
            const status = localStorage.getItem('loginStatus');

            if (status === 'loggedOut') {
                localStorage.setItem('loginStatus', '');
                alert('Your session has expired. Please login again.');
            }
        });
    };

    return {prepareAjaxSetup};
};

export default useAjaxSetup;
