import React from 'react';

const PublicTopBar = () => {
    return (
        <div className="navbar navbar-inverse">
            <div className="navbar-header"/>
        </div>
    );
};

export default PublicTopBar;
