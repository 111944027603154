import  {
    React, useLocation, AdminRoutes, useEffect
}  from 'library/base/baseContainerImports';
import $ from "jquery";
import {useDispatch, useSelector} from "react-redux";
import useInitialize from "../library/hooks/useInitialize";
import '../css/cssImports';
import LayoutContainer from "../layouts/LayoutContainer";
import PublicContainer from "../layouts/public/PublicContainer";
import {authSuccess} from "../modules/Admin/User/Login/store/actions";
import useAjaxSetup from "../library/hooks/useAjaxSetup";

const App = () => {
    const initSuccess = useInitialize();
    const userToken = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const {prepareAjaxSetup} = useAjaxSetup();

    /**
     * This is where init functions are called - once per application load
     */
    useEffect(() => {
        console.log(initSuccess);

        setAjaxDefaults();
    }, []);

    const signupLayoutUrls = [
        AdminRoutes.ADMIN.SIGNUP.path ,
        AdminRoutes.ADMIN.SIGNUP.path + "/" ,
    ];

    const location = useLocation();

    const isLoggedIn = () => {
        if(signupLayoutUrls.indexOf(location.pathname) !== -1) {
            return true;
        }
        return userToken;
    };

    const setAjaxDefaults = () => {
        prepareAjaxSetup();

        $(document).ajaxSuccess(function (event, request) {
            const newToken = request.getResponseHeader('token');
            if (newToken) {
                dispatch(authSuccess(newToken));
            }
        });
    };

    return (
        <>
            {isLoggedIn() ? <LayoutContainer/> : <PublicContainer/>}
        </>
    );
};

export default App;
