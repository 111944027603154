import React, {useEffect} from 'react';
import './tableImports';
import useInitDatatable from 'theme/js/pages/datatables_responsive.js';
import initDatatableSelect from 'theme/js/pages/datatables_extension_select.js';
import $ from "jquery";
import {useHistory} from "react-router-dom";
import useAjaxSetup from "../../hooks/useAjaxSetup";

const Table = ({tableJson, extraClass, action, buttonLinkParams,afterTableRender}) => {
    const history = useHistory();
    const {prepareAjaxSetup} = useAjaxSetup();
    useInitDatatable();
    let {id, row_ordering, row_ordering_column, pagination, searching, params, buttons} = tableJson;
    let tableClass = extraClass ?? '';

    let getParams = function (url) {
        let params = {};
        let parser = document.createElement('a');
        parser.href = url;
        let query = parser.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };


    if (buttonLinkParams != undefined){
        let buttonJson = JSON.parse(buttons);
        for (let i=0;i<buttonJson.length;i++){
            let urlParams = getParams(buttonJson[i].link);
            for (const key in buttonLinkParams){
                if (urlParams[key] != undefined){
                    buttonJson[i].link = buttonJson[i].link.replace(
                        key + '=' + urlParams[key],
                        key + '=' + buttonLinkParams[key]
                    );
                }else{
                    if (buttonJson[i].link.indexOf('?') == -1){
                        buttonJson[i].link = buttonJson[i].link + '?' +
                            key + '=' + buttonLinkParams[key];
                    }else{
                        buttonJson[i].link = buttonJson[i].link + '&' +
                            key + '=' + buttonLinkParams[key];
                    }
                }
            }
        }
        buttons = JSON.stringify(buttonJson);
    }

    useEffect(() => {
        initDatatableSelect();
        prepareAjaxSetup();
        bindTableLinks();
        //console.log(afterTableRender);
        if (typeof afterTableRender === "function") {
            afterTableRender();
        }
    }, []);

    /**
     * This method handles table links/ button generated by data tables
     */
    const bindTableLinks = () => {
        $("table.datatable-xtreme a").on("click", (e) => {
            e.preventDefault();
            const path = $(this).attr('href');
            if (path) {
                history.push(path);
            }
        });
    };

    const getPagination = () => {
        return 'pagination=' + pagination;
    };

    const getSearching = () => {
        return 'searching=' + searching;
    };

    const createTableHeader = () => {
        let headerColumnArray = [];
        const headerColumns = params['colModel'];

        for (const key in headerColumns) {
            if (headerColumns.hasOwnProperty(key)) {
                const column = headerColumns[key];
                let sortable = true;
                let align = 'left';
                let width = '';
                if (!column['sort']) {
                    sortable = false;
                }

                if (column['width']) {
                    width = 'data-width="' + column['width'] + '"';
                }

                if (column['type'] === 'number') {
                    align = 'center';
                } else if (column['align']) {
                    align = column['align'];
                }

                headerColumnArray.push(<th className={"text-" + align} {...width}
                                           data-orderable={sortable}>{params['colNames'][key]}</th>)
            }
        }

        return headerColumnArray;
    };

    return (
        <table id={id + "_dataset"} data-roword={row_ordering}
               data-rowordcol={row_ordering_column}
               class={tableClass + " table datatable-xtreme"} {...getPagination()} {...getSearching()} data-ajax={action} data-button-config={buttons}
              >
            <thead>
            <tr>
                <th data-class-name="control" data-orderable="false"/>
                {createTableHeader()}
            </tr>
            </thead>
            <tbody>

            </tbody>
        </table>
    )
};

export default Table;
