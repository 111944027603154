import 'jquery';
import 'summernote';
// import '../../../../../theme/js/plugins/editors/summernote/summernote.min.js';
// import     '../../../../../theme/js/plugins/editors/summernote/summernote-ext-specialchars.js';
// import    '../../../../../theme/js/plugins/editors/summernote/summernote-ext-print.js';
// import 'jquery-steps';
// import    '../../../../../theme/js/plugins/forms/wizards/steps.min.js';

import 'bootstrap';
// import   '../../../../../theme/js/core/libraries/bootstrap.min.js';
// import   '../../../../../theme/js/core/libraries/jasny_bootstrap.min.js';

import 'validate';
// import    '../../../../../theme/js/plugins/forms/validation/validate.min.js';

import 'cookie';
// import   '../../../../../theme/js/plugins/extensions/cookie.js';

import 'typeahead';
// import   '../../../../../theme/js/pages/editor_summernote.js';

import 'handlebars';
// import   '../../../../../theme/js/plugins/forms/inputs/typeahead/typeahead.bundle.min.js';
// import    '../../../../../theme/js/plugins/forms/inputs/typeahead/handlebars.js';

import 'select2';
// import   '../../../../../theme/js/plugins/forms/selects/select2.min.js';

import 'bootstrap-fileinput';
// import  '../../../../../theme/js/plugins/uploaders/fileinput.min.js';


