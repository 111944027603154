import {
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_PENDING,
    AUTH_LOGOUT,
    AUTH_SET_USER
} from './constants';

export const authSuccess = (token) => {
    return {
        type: AUTH_SUCCESS,
        token
    };
};

export const authFail = () => {
    return {
        type: AUTH_FAIL
    };
};

export const authPending = () => {
    return {
        type: AUTH_PENDING
    };
};

export const authLogout = () => {
    return {
        type: AUTH_LOGOUT
    };
};

export const authSetUser = (user) => {
    return {
        type: AUTH_SET_USER,
        user
    };
};
