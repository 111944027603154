import React from "react";

const InputInlineText = ({id, options, type, value, icon}) => {

    const getInputAttributes = () => {
        let attributes = {
            type: type,
            id: id,
            name: id,
            defaultValue: value,
            class: "form-control",
        };

        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                attributes[key] = options[key];

            }
        }

        return attributes;
    };

    return (
        <>
            <input {...getInputAttributes()}/>
            <div className="form-control-feedback">
                <i className={icon + ' text-muted'}/>
            </div>
        </>
    );
};

export default InputInlineText;
