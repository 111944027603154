import React from 'react';
import TopBar from "./layoutComponents/topBar/TopBar";
import Content from "./layoutComponents/Content";
import SideBar from "./layoutComponents/sidebar/SideBar";

const LeftMenuTopBarLayout = () => {

    return (
        <>
            <TopBar/>
            <div className="page-container" style={{minHeight: '95vh'}}>
                <div className="page-content">
                    <SideBar/>
                    <Content/>
                </div>
            </div>
        </>
    );
};

export default LeftMenuTopBarLayout;
