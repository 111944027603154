import {
    USER_META_SUCCESS,
    USER_META_FAIL,
    USER_META_PENDING,
} from './constants';

const initialState = {
    name: '',
    meta: {},
    side: [],
    top: [],
    isUserMetaPending: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_META_SUCCESS:
            return {
                ...state,
                isAuthPending: false,
                name: action.metaData.name,
                meta: action.metaData.meta,
                side: action.metaData.navigation.side,
                top: action.metaData.navigation.top,
            };
        case USER_META_FAIL:
            return {
                ...state,
                isAuthPending: false,
            };

        case USER_META_PENDING:
            return {
                ...state,
                isAuthPending: true,
            };

        default:
            return state;
    }
};
