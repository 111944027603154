import {
    USER_META_SUCCESS,
    USER_META_FAIL,
    USER_META_PENDING,
} from './constants';

export const userMetaSuccess = (metaData) => {
    return {
        type: USER_META_SUCCESS,
        metaData
    };
};

export const userMetaFail = () => {
    return {
        type: USER_META_FAIL
    };
};

export const userMetaPending = () => {
    return {
        type: USER_META_PENDING
    };
};
