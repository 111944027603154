import {createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducer';

const persistConfig = {
    key: 'root',
    storage,
};

export const initialState = {
    token: '',
    isAuthPending: false
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Persisted storage gets merged when a page refresh occurs
 *
 * @returns {{store: any, persistor: Persistor}}
 */
export default () => {
    let store = createStore(persistedReducer, initialState);
    let persistor = persistStore(store);
    return {store, persistor}
}
