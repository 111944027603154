import React from 'react';
import PageHeader from "layouts/layoutComponents/PageHeader";
import Form from "library/basic/forms/Form";
import useFormInit from "../useFormInit";

const Container = ({formAction, formDefinitionAction, mainForm, title,onFormRender}) => {    // NOTE: Main Form usage has been removed for all add forms
    const {fetchFormData} = useFormInit({formAction});

    const onRender = () => {
        fetchFormData();
        if (typeof onFormRender === 'function'){
            onFormRender();
        }
    };

    return (
        <>
            <PageHeader/>
            <div className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-flat">
                            <div className="panel-heading">
                                <h5 className="panel-title text-semibold">{title}</h5>
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><a data-action="collapse"/></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="panel-body">
                                <Form
                                    id="form-data"
                                    action={formAction}
                                    formClass='form_data generic_form'
                                    encType='application/x-www-form-urlencoded'
                                    jsonLoadAction={formDefinitionAction}
                                    jsonLoadActionHandler={onRender}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Container;
