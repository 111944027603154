import {
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_PENDING,
    AUTH_LOGOUT,
    AUTH_SET_USER
} from './constants';

const initialState = {
    token: '',
    isAuthPending: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SUCCESS:
            return {
                ...state,
                isAuthPending: false,
                token: action.token
            };
        case AUTH_FAIL:
            return {
                ...state,
                isAuthPending: false,
            };

        case AUTH_PENDING:
            return {
                ...state,
                isAuthPending: true,
            };

        case AUTH_LOGOUT:
            return {
                ...state,
                token: '',
                isAuthPending: false
            };

        case AUTH_SET_USER:
            return {
                ...state,
                user: action.user
            };

        default:
            return state;
    }
};
