import React, {useEffect, useState} from 'react';
import {useAppRoutes} from "../../routes/routes";
import {useLocation} from "react-router";
import each from 'foreach-array';
import CountdownTimer from "./CountdownTimer";

/**
 * How this works: checks the current URL and get route object corresponds to that.
 * Refers `route.pageHeaderLinks` array to build up middle links
 */
const PageHeader = ({timerTitle, secondsToGo}) => {
    const {getRoutes} = useAppRoutes();
    const ROUTES = getRoutes();
    const location = useLocation();
    const [isMiddleLinksAvailable, setIsMiddleLinksAvailable] = useState(false);
    const [title, setTitle] = useState('');
    const [countdownTimer, setCountdownTimer] = useState('');

    useEffect(() => {
        each(ROUTES, (route) => {
            if (route.path === location.pathname || (route.path + '/') === location.pathname || route.path === (location.pathname + '/')) {
                setIsMiddleLinksAvailable(true);
                setTitle(route.title);
            }
        });
        if (secondsToGo > 0) {
            setCountdownTimer(displayCountDownTimer(timerTitle, secondsToGo));
        }

    }, [title, isMiddleLinksAvailable]);

    const getMiddleLinks = () => {
        const middleLinkArray = [];

        each(ROUTES, (route) => {
            if ((route.path === location.pathname || (route.path + '/') === location.pathname) && route.pageHeaderLinks) {
                each(route.pageHeaderLinks, (link) => {
                    middleLinkArray[middleLinkArray.length] = <li>
                        <a href={link.url}>
                            <i className={link.icon + " position-left"}/>{link.title}</a>
                    </li>
                });
            }
        });

        return middleLinkArray;
    };

    return (
        <div className="page-header">
            <div className="page-header-content">
                <div className="page-title">
                    <h4><i className=" position-left"/> <span className="text-semibold">{title}</span></h4>
                </div>
                {countdownTimer}
            </div>

            <div className="breadcrumb-line">
                <ul className="breadcrumb">
                    <li><a href="/admin/">
                        <i className="icon-home2 position-left"/> Home</a></li>
                    <>{isMiddleLinksAvailable && getMiddleLinks()}</>
                    <li className="active">{title}</li>
                </ul>
            </div>
        </div>
    );
};

export default PageHeader;

function displayCountDownTimer(timerTitle, secondsToGo) {
        return (
            <CountdownTimer
                timerTitle={timerTitle}
                secondsToGo={secondsToGo}
            />
        );
};