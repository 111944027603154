// generic_css
import '../theme/css/bootstrap.css';
import '../theme/css/core.css';
import '../theme/css/components.css';
import '../theme/css/summernote.css';
import '../theme/css/jasny-bootstrap.css';
import '../theme/css/colors.css';
import '../theme/css/custom.css';

// // generic_front_css
import    '../theme/css/core.css';
import   '../theme/css/components.css';
import   '../theme/css/summernote.css';
// import   '../theme/css/colors.css';
import   '../theme/css/custom.css';
//
// // generic_print_css
// import    '../theme/css/print.css';
// import   '../theme/css/colors.css';


// calendar_css
import '../theme/css/calendar/jquery.dop.Select.css';
import '../theme/css/calendar/jquery.dop.frontend.BSPCalendar.css';
import "daterangepicker/daterangepicker.css";
