/* eslint-disable */
import {getMetaInfo} from '../../service/request-handler';
import {urlConfigs} from "../../configs/config";
import {useDispatch, useSelector} from "react-redux";
import {userMetaSuccess} from "../../layouts/layoutComponents/sidebar/store/actions";


const useUserMeta = () => {
    const formAction    = urlConfigs.BASE_URL + '/rest/acl';
    const dispatch = useDispatch();

    const getUserMeta = (token, successFn) => {
        const getInfoSuccess = (data) => {
            if (data) {
                dispatch(userMetaSuccess(data));
            }

            if (typeof successFn === 'function') {
                successFn();
            }
        };

        getMetaInfo(formAction, token, getInfoSuccess, () => alert('User Meta Request failed'));
    };

    return {
        getUserMeta
    }
};

export default useUserMeta;
