import React, {useEffect} from 'react';
import $ from 'jquery';
import {initApp} from "theme/js/core/app";
import usePopulate from "../hooks/usePopulate";
import {getInfo} from "../../service/request-handler";
import {initMultiSelect} from "../../theme/js/pages/form_multiselect";

const useFormInit = ({formId, formAction, formCss, formAfterPopulate, isJsonFileInit}) => {
    const populateForm = usePopulate(formId ? formId : 'form-data');

    useEffect(() => {
        if (isJsonFileInit) {
            fetchFormData();
        }
    }, []);

    const handleUpdate = () => {
        const updateButton = $("#update");
        if (updateButton && typeof updateButton.click === 'function') {
            updateButton.unbind('click').click(function () {
                $("." + (formCss ? formCss : "form_data")).processForm();
                return false;
            });
        }
    };

    const fetchFormData = () => {
        if (formAction) getInfo(formAction, getInfoSuccess, () => console.error('Something went wrong'));
        handleUpdate();
    };

    const getInfoSuccess = (data) => {
        initApp(); // This needs to be included in every container which has collapsible menus
        initMultiSelect();

        populateForm(data);
        $("textarea").trigger('keyup');
        if (formAfterPopulate && typeof formAfterPopulate === 'function') formAfterPopulate(data);
    };

    return {fetchFormData}
};

export default useFormInit;
