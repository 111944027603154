import React, {useEffect} from "react";
import $ from 'jquery';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const TopBarSettings = () => {
    const topConfig = useSelector(state => state.userMeta.top);

    return (
        <li className="dropdown dropdown-user">
            <a className="dropdown-toggle" data-toggle="dropdown">
                <i className="icon-gear"/>
                <span className="visible-xs-inline-block position-right">Account Settings</span>
                <i className="caret"/>
            </a>
            <ul className="dropdown-menu dropdown-menu-right top-bar-navigation">
                {displayRouteMenu(topConfig)}
            </ul>
        </li>

    );
};

export default TopBarSettings;


function displayRouteMenu(routes) {
    return (
        <>
            {routes.map((route, index) => {
                return (
                    <>
                        <SingleRoute route={route}/>
                    </>
                )
            })}
        </>
    );
}

export const SingleRoute = ({route}) => {
    const history = useHistory();

    useEffect(() => {
        $("ul.top-bar-navigation").on("click", "li", function () {
            $("ul.top-bar-navigation li").removeClass("active");
            $(this).addClass("active");
        });

        activateCurrentMenu();
    }, []);

    const activateCurrentMenu = () => {
        const current = window.location.pathname;

        $('ul.top-bar-navigation li a').each(function(){
            const $this = $(this);
            if($this.attr('href') === current){
                $this.parent('li').addClass('active');
            }
        })
    };

    const navigateToPath = (e, path) => {
        e.preventDefault();

        if (path) {
            history.push(path);
        }
    };

    if (route.class === 'navigation-header'){
        return (
            <li className="divider"/>
        );
    }else{

        return (
            <li key={route.key} className={route.class}>
                <a href={route.href || route.path} onClick={ e => navigateToPath(e, route.href || route.path) }>
                    <i className={route.iconClass || 'icon-file-text2'}> </i>
                    <span>{route.title || route.key}</span>
                </a>
            </li>
        );

    }

};
