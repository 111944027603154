/* eslint-disable */
import React, {useEffect, useState} from 'react';
import '../table/tableImports';
import './elements/imports/formImports';
import initUploaderBootstrap from 'theme/js/pages/uploader_bootstrap.js';
import {initDataMask} from 'theme/js/core/jasny-bootstrap.js';

import {getInfo} from 'service/request-handler';

import useChildElements from '../../hooks/useChildElements';
import useInitDatatable from "theme/js/pages/datatables_responsive";
import initDatatableSelect from "theme/js/pages/datatables_extension_select";
import useAjaxSetup from "../../hooks/useAjaxSetup";

const Form = ({encType, action, formClass, method, jsonArray, jsonLoadAction, jsonLoadActionHandler, id}) => {

    const [jsonApiArray, setJsonApiArray] = useState();
    const {prepareAjaxSetup} = useAjaxSetup();
    useInitDatatable();

    useEffect( () => {
        initUploaderBootstrap();
        initDataMask();
        initDatatableSelect();
        prepareAjaxSetup();

        if (jsonLoadAction) {
            getInfo(jsonLoadAction, async function (data) {
                await setJsonApiArray(data);

                if (typeof jsonLoadActionHandler === "function") {
                    jsonLoadActionHandler();
                }
            }, () => alert('Something went wrong. Could not load requested data.'));
        }

    }, []);
    const renderer = () => {
        return useChildElements(jsonApiArray ? jsonApiArray : jsonArray);
    };


    return (
        <form encType={encType} action={action} id={id} autoComplete="off"
              className={formClass} method="post">
            <dl className="zend_form">
                {renderer()}
            </dl>
        </form>
    );
};

export default Form;
