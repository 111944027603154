import React from 'react';
import Signup from '../../modules/Admin/User/Signup/Signup';
import Login from '../../modules/Admin/User/Login/login';
import ForgottenPassword from 'modules/Admin/User/ForgottenPassword/ForgottenPassword'
import ResetPassword from 'modules/Admin/User/ResetPassword/ResetPassword'
import ActivateAccount from 'modules/Admin/User/ActivateAccount/ActivateAccount'
import PublicTopBar from "../layoutComponents/PublicTopBar";
import {Route, Switch} from "react-router";

/**
 * This layout will load Public pages
 */
const PublicContainer = () => {
    return (
        <>
            <PublicTopBar/>
            <Switch>
                <Route path='/admin/forgotten-password' exact={true} component={ForgottenPassword}/>
                <Route path='/admin/reset-password' exact={true} component={ResetPassword}/>
                <Route path='/admin/activate' exact={true} component={ActivateAccount}/>
                <Route path='/admin/login' exact={true} component={Login}/>
                <Route component={Signup}/>
            </Switch>
        </>
    );
};

export default PublicContainer;
