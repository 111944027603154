/* ------------------------------------------------------------------------------
*
*  # Bootstrap multiple file uploader
*
*  Specific JS code additions for uploader_bootstrap.html page
*
*  Version: 1.1
*  Latest update: Dec 11, 2015
*
* ---------------------------------------------------------------------------- */
import $ from 'jquery';

const initUploaderBootstrap = () => {
    $(function () {
        // Basic example
        $('.file-input').fileinput({
            browseLabel: 'Browse',
            browseIcon: '<i class="icon-file-plus"></i>',
            uploadIcon: '<i class="icon-file-upload2"></i>',
            removeIcon: '<i class="icon-cross3"></i>',
            layoutTemplates: {
                icon: '<i class="icon-file-check"></i>',
                caption: '<div class="file-caption form-control {class}" {tabIndexConfig}>\n' +
                    '  <span class="file-caption-icon"><i class="icon-file-check"></i></span>\n' +
                    '  <input class="file-caption-name" onkeydown="return false;" onpaste="return false;">\n' +
                    '</div>',
            },
            ajaxSettings:{
                beforeSend: (xhr) => {
                    const userToken = localStorage.getItem('userToken');
                    if (userToken) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + userToken);
                    } else {
                        xhr.setRequestHeader('Authorization', 'Bearer Public');
                    }
                },
            },
            uploadExtraData: function (previewId, index) {
                var obj = {};
                const userToken = localStorage.getItem('userToken');
                if (userToken) {
                    obj['Authorization'] = 'Bearer ' + userToken;
                } else {
                    obj['Authorization'] = 'Bearer Public';
                }
                return obj;
            },
            initialCaption: "No file selected"
        }).on('filebatchuploadcomplete', function(event, preview, config, tags, extraData) {
            window.location.reload();
        });


        // Custom layout
        $('.file-input-custom').fileinput({
            previewFileType: 'image',
            browseLabel: 'Select',
            browseClass: 'btn bg-slate-700',
            browseIcon: '<i class="icon-image2 position-left"></i> ',
            removeLabel: 'Remove',
            removeClass: 'btn btn-danger',
            removeIcon: '<i class="icon-cancel-square position-left"></i> ',
            uploadClass: 'btn bg-teal-400',
            uploadIcon: '<i class="icon-file-upload position-left"></i> ',
            layoutTemplates: {
                icon: '<i class="icon-file-check"></i>'
            },
            initialCaption: "Please select image",
            mainClass: 'input-group'
        });


        // Template modifications
        $('.file-input-advanced').fileinput({
            browseLabel: 'Browse',
            browseClass: 'btn btn-default',
            removeClass: 'btn btn-default',
            uploadClass: 'btn bg-success-400',
            browseIcon: '<i class="icon-file-plus"></i>',
            uploadIcon: '<i class="icon-file-upload2"></i>',
            removeIcon: '<i class="icon-cross3"></i>',
            layoutTemplates: {
                icon: '<i class="icon-file-check"></i>',
                main1: "{preview}\n" +
                    "<div class='input-group {class}'>\n" +
                    "   <div class='input-group-btn'>\n" +
                    "       {browse}\n" +
                    "   </div>\n" +
                    "   {caption}\n" +
                    "   <div class='input-group-btn'>\n" +
                    "       {upload}\n" +
                    "       {remove}\n" +
                    "   </div>\n" +
                    "</div>"
            },
            initialCaption: "No file selected"
        });


        // Custom file extensions
        $(".file-input-extensions").fileinput({
            browseLabel: 'Browse',
            browseClass: 'btn btn-primary',
            uploadClass: 'btn btn-default',
            browseIcon: '<i class="icon-file-plus"></i>',
            uploadIcon: '<i class="icon-file-upload2"></i>',
            removeIcon: '<i class="icon-cross3"></i>',
            layoutTemplates: {
                icon: '<i class="icon-file-check"></i>'
            },
            maxFilesNum: 10,
            allowedFileExtensions: ["jpg", "gif", "png", "txt"]
        });


        // Always display preview
        $(".file-input-preview").fileinput({
            browseLabel: 'Browse',
            browseIcon: '<i class="icon-file-plus"></i>',
            uploadIcon: '<i class="icon-file-upload2"></i>',
            removeIcon: '<i class="icon-cross3"></i>',
            layoutTemplates: {
                icon: '<i class="icon-file-check"></i>'
            },
            initialPreview: [
                "<img src='assets/images/placeholder.jpg' class='file-preview-image' alt=''>",
                "<img src='assets/images/placeholder.jpg' class='file-preview-image' alt=''>",
            ],
            overwriteInitial: false,
            maxFileSize: 100
        });


        // Display preview on load
        $(".file-input-overwrite").fileinput({
            browseLabel: 'Browse',
            browseIcon: '<i class="icon-file-plus"></i>',
            uploadIcon: '<i class="icon-file-upload2"></i>',
            removeIcon: '<i class="icon-cross3"></i>',
            initialPreview: [
                "<img src='assets/images/placeholder.jpg' class='file-preview-image' alt=''>",
                "<img src='assets/images/placeholder.jpg' class='file-preview-image' alt=''>",
            ],
            overwriteInitial: true
        });


        // Disable/enable button
        $("#btn-modify").on("click", function () {
            let $btn = $(this);
            if ($btn.text() == "Disable file input") {
                $("#file-input-methods").fileinput("disable");
                $btn.html("Enable file input");
                alert("Hurray! I have disabled the input and hidden the upload button.");
            } else {
                $("#file-input-methods").fileinput("enable");
                $btn.html("Disable file input");
                alert("Hurray! I have reverted back the input to enabled with the upload button.");
            }
        });


        // AJAX upload
        $(".file-input-ajax").fileinput({

            previewFileType: 'image',
            browseLabel: 'Select',
            browseClass: 'btn bg-slate-700',
            browseIcon: '<i class="icon-image2 position-left"></i> ',
            layoutTemplates: {
                icon: '<i class="icon-file-check"></i>'
            },
            initialCaption: "Please select image",
            mainClass: 'input-group',

            previewSettings: {image: {width: "auto", height: "180px"}},

            uploadUrl: "http://localhost", // server upload action
            uploadAsync: true,
            maxFileCount: 1,
            initialPreview: [],
            fileActionSettings: {
                removeIcon: '<i class="icon-bin"></i>',
                removeClass: 'btn btn-link btn-xs btn-icon',
                uploadIcon: '<i class="icon-upload"></i>',
                uploadClass: 'btn btn-link btn-xs btn-icon',
                indicatorNew: '<i class="icon-file-plus text-slate"></i>',
                indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
                indicatorError: '<i class="icon-cross2 text-danger"></i>',
                indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>',
            }
        });


    });
};

export default initUploaderBootstrap;
